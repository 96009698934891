.btn {
    @include button($grow: 0, $flex: auto);
    @include buttonStyle;
    @include buttonHover;
    @include buttonFocus;
}

.link {
    @extend .btn;
    flex: 0 0 25%;
}

.previous,
.next {
    @include button($grow: 0, $flex: var(--size-icon-xlarge));
    @include buttonStyle;
    background-color: transparent;
    border-color: transparent;
    pointer-events: auto;

    i {
        display: block;
        width: var(--size-icon-xlarge);
        height: var(--size-icon-xlarge);
        color: var(--color-button-bg-h);
        font-family: var(--font-awesome);
        font-size: var(--size-icon-xlarge);
    }
    
    &:hover i,
    &:focus i {
        color: var(--color-button-bg);
    }
    
    &:focus:not(:focus-visible) i {
        color: var(--color-button-bg);
    }
}
.previous i:before {
    content: "\f053";
}
.next i:before{
    content: "\f054";
}

.buy {
    @extend .btn;
    flex: 0 0 auto;
    margin: 0 0 0 0;

    @include breakpoint(small) {
        flex: 1 0 50%;
    }

    &:focus-visible {
        color: var(--color-button-fg-h);
        border: var(--focus-border-active);
    }

    &:hover,
    &:focus:not(:focus-visible) {
        color: var(--color-button-fg-h);
    }
}
