// theme colors light to dark
// http://www.color-hex.com/color-palette/51917
// https://material.io/color/#!/?view.left=0&view.right=0&primary.color=0277BD

// required for svg fill
    $svg-fill: null !default;

// alpha declarations
    $alpha-full: 1;
    $alpha-3quarter: .75;
    $alpha-half: .5;
    $alpha-quarter: .25;
    $alpha-eighth: .125;
    $alpha-none: 0;

// #generic transparent, black and white variations
    $color-black: rgba(0, 0, 0, $alpha-full);
    $color-black-3quarter: rgba($color-black, $alpha-3quarter);
    $color-black-half: rgba($color-black, $alpha-half);
    $color-black-quarter: rgba($color-black, $alpha-quarter);
    $color-black-eighth: rgba($color-black, $alpha-eighth);

    $color-white: rgba(255, 255, 255, $alpha-full);
    $color-white-half: rgba($color-white, $alpha-half);

    $color-transparent: rgba($color-black, 0);

// #theme
    $color-theme-primary: rgba(60, 200, 0, 1);
    // $color-theme-primary-light: tint($color-theme-primary, 20%);
    $color-theme-primary-dark: rgba(48, 160, 0 ,1); //shade($color-theme-primary, 20%);

// layout colors
    $color-icon-primary: $color-theme-primary;
    $color-error: rgba(255, 80, 80, 1);


// max page width
$size-max: 1200px;

// minimum touch sizing
$size-touch: 40px;

// border-radius
$size-border-radius: .25rem;
$size-border-radius-full: 100%;
$size-border-radius-switch: 50px;
$size-border-radius-meter: 50px;

// #spacing (padding/margin)
$size-root-eigth: .125rem;
$size-root-quarter: .25rem;
$size-root-half: .5rem;
$size-root-3quarter: .75rem;
$size-root-full: 1rem;
$size-root-2x: 2rem;

// icon sizes
$size-icon-xlarge: 32px;
$size-icon-large: 24px;
$size-icon-medium: 16px;
$size-icon-small: 12px;

// container sizes
$size-nav-height: 80px;
$size-nav-content-height: 90vh;
$size-nav-content-offset: 10vh;
$size-header-height: 90vh;
$size-content-height: 90vh;
$size-footer-height: 80px;

// z-index
$depth-negative: -1;
$depth-header: 4;
$depth-navToggle:4;
$depth-nav: 3;
$depth-modal: 5;
$depth-prelaoder: 5;
$depth-headings: 2;
$depth-content: 1;

// component
$size-width-scrollbar: 2px;


// pathing
$pathToImages:  'https://cdn.kidkrazy.net/kidkrazydotnet/_images/';
$pathToBrand:   'https://cdn.kidkrazy.net/kidkrazydotnet/_images/brand/';
$pathToHero:    'https://cdn.kidkrazy.net/kidkrazydotnet/_images/hero/';
$pathToFonts:    '/fonts/';

// for fontawesome this is required
$fa-font-path: "../webfonts";