:root {

    // color
    // --color-primary:;
    // --color-secondary:;
    --color-link: rgba(0, 170, 255, 1);
    --color-link-h: rgba(0, 170, 255, .5);
    --color-background-primary: rgba(18, 20, 23, 1);
    --color-background-secondary: rgba(40, 44, 52, .5);
    --color-background-header: rgba(18, 20, 23, 1);
    --color-background-nav: rgba(18, 20, 23, 1);

    --color-text: rgba(230, 230, 230, 1);
    --color-text-subtle: rgba(230, 230, 230, .75);
    --color-text-dark: rgba(0, 0, 0, .7);
    --color-white-transparent: rgba(230, 230, 230, .2);

    --color-gallery-overlay: rgba(0, 0, 0, .7);

    //typography
    --font-awesome: "Font Awesome 6 Free";
    --font-awesome-brands: "Font Awesome 6 Brands";

    //ui
    // --backdrop-filter-header: brightness(1) blur(2px);
    // --backdrop-filter-nav: brightness(0.1) blur(2px);
    --box-shadow: 0px 0.5rem 1rem -0.125rem rgba(0, 0, 0, 0.5);
    --box-shadow-shallow: 0px 1px 4px 0 rgba(0, 0, 0, 0.5);
    --box-shadow-inset: inset 0px 0px 2px 4px rgba(0, 0, 0, 0.5);
    --text-shadow: 1px 2px 0.25rem rgba(0, 0, 0, 0.75);
    --color-border-all: rgba(140, 140, 140, 1);

    // size
    --width-max: 1200px;
    --size-font: 1rem;
    --ratio-square: 1/1;
    
  // views

    //album
    --color-album-divider: rgba(0, 170, 255, 1);
    --size-album-art: 100%;
    --grid-album: auto / minmax(33%, 400px) minmax(67%, 800px); // equates to max 1200px
    --grid-album-small: auto / 100%;
    --grid-album-areas: "art header" "art tracks";
    --grid-album-areas-small: "art" "header" "tracks";
    --grid-album-area-art: "content" / 1 / col1-start / last-line / col1-end;

    // contact
    --size-contact-form: 50vw;
    --size-contact-form-max: 600px;

    // content
    --size-content: 800px;

    //forms
    --font-family-button: var(--font-family);
    --font-weight-button: var(--font-weight);

    --duration-button: 0.25s;
    --color-button-fg: rgba(255, 255, 255, 1);
    --color-button-bg: rgba(0, 170, 255, 1);
    --color-button-border: rgba(0, 170, 255, 1);
    --size-button-border: 2px;
    --border-radius-button: #{$size-border-radius};

    --color-button-fg-h: rgba(255, 255, 255, 1);
    --color-button-bg-h: rgba(0, 170, 255, .5);
    --color-button-border-h: rgba(0, 170, 255, .5);
    --size-button-border-h: 2px;
    --border-radius-button-h: #{$size-border-radius};

    --color-label: rgba(0, 170, 255, 1);
    --color-placeholder: rgba(140, 140, 140, 1);
    --color-placeholder-f: rgba(0, 170, 255, 1);

    --color-input-fg: var(--color-text-dark);
    --color-input-bg: rgba(235, 235, 235, 1);
    --color-input-border: transparent;

    --color-range-thumb: rgba(0, 170, 255, 1);
    --color-range-value: rgba(160, 160, 160, 1);
    --color-range-bg: rgba(235, 235, 235, 1);
    --color-range-border: var(--color-border-all);

    --color-select-fg: var(--color-text-dark);
    --color-select-bg: rgba(235, 235, 235, 1);
    --color-select-border: var(--color-border-all);

    --color-checkbox-check: rgba(0, 170, 255, .5);
    --color-checkbox-fg: var(--color-text-dark);
    --color-checkbox-bg: transparent;
    --color-checkbox-border: var(--color-border-all);

    //table
    --color-table-row-alt: rgba(140, 140, 140, 1);

    //scrollbars
    --color-track: rgba(40, 44, 52, 1);
    --color-thumb: rgba(0, 170, 255, 1);
    --size-width-track: var(--size-root-quarter);
    --size-width-scrollbar: var(--size-root-quarter);
}