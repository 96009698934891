.nav {
    z-index: $depth-nav;
    position: fixed;
    top: 0;
    right: 0;
    background-color: var(--color-background-nav);
    height: 100vh;
    align-content: flex-start;
    padding: var(--size-header) var(--size-root-full) var(--size-root-full) var(--size-root-full);
    overflow-y: scroll;
    scroll-behavior: smooth;

    // animated properties start
    opacity: 0;
    transform: translate3d(100vw, 0, 10px);
    transition: all var(--duration) ease-in-out;

    &--active {
        opacity: 1;
        transform: translate3d(0vw, 0, 10px);
    }

    hr {
        width: 10vw;
        margin: var(--size-root-full) auto var(--size-root-full) 0;
        border: none;
        border-bottom: var(--size-hr) solid var(--color-album-divider);
    }

    ul:not(:first-child) {
        margin-bottom: var(--size-root-2x);
    }

    li {
        list-style-type: none;
    }

    li a {
        display: block;
        padding: 0.5rem 0;
    }

    & .btn--active {
        color: var(--color-link-h);
    }

    p {
        font-size: small;
    }

    .copyright {
        align-self: flex-end;
        font-size: small;
        color: var(--color-text);
    }
}

.toggle {
    z-index: $depth-navToggle;
    -webkit-appearance: none;
    display: flex;
    align-content: stretch;
    align-items: center;
    width: fit-content;
    margin: 0;
    padding: var(--size-root-half);
    color: var(--color-link);
    font-size: var(--size-font-small);
    border: var(--focus-border-hidden);
    background: transparent;
    font-family: var(--font-awesome);
    cursor: pointer;

    &:focus-visible {
        color: var(--color-link-h);
        border: var(--focus-border-active);
    }

    &:hover,
    &:focus:not(:focus-visible) {
        color: var(--color-link-h);
    }

    &:before {
        height: var(--size-icon-large);
        width: var(--size-icon-large);
        font-family: var(--font-awesome);
        font-size: var(--size-icon-large);
    }

    &:after {
        font-size: 0;
    }

    // icon specific
    &__menu {
        justify-self: flex-start;

        &--open {
            &:before {
                content: "\f0c9";
            }

            &:after {
                content: "Menu";

                @include breakpoint(small) {
                    content: "";
                }
            }
        }

        &--close {
            &:before {
                content: "\f00D";
            }

            &:after {
                content: "Close";

                @include breakpoint(small) {
                    content: "";
                }
            }
        }
    }
}
