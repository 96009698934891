.album {
    display: grid;
    grid-template: var(--grid-album);
    grid-template-areas: var(--grid-album-areas);
    gap: var(--size-root-full);
    justify-self: center;
    align-items: stretch;
    padding: var(--size-root-full);
    animation: fadeIn var(--duration);

    @include breakpoint(small) {
        grid-template: var(--grid-album-small);
        grid-template-areas: var(--grid-album-areas-small);
    }

    &__header {
        position: relative;
        grid-area: header;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        grid-template: auto / 1fr;
        gap: var(--size-root-half) var(--size-root-full);
        width: 100%;
        word-break: break-word;

        span {
            display: flex;
            justify-content: space-between;

            @include breakpoint(small) {
                flex: 1 0 100%;
            }
        }

        hr {
            width: 10vw;
            margin: var(--size-root-full) 60% var(--size-root-full) 0;
            border: none;
            border-bottom: var(--size-hr) solid var(--color-album-divider);
        }

        &--title {
            flex: 1 0 100%;
            font-size: xx-large;
            text-shadow: var(--text-shadow);
        }

        &--description {
            width: 100%;
        }

        &--artist {
            font-size: small;
            color: var(--color-text-subtle);
        }

        &--year {
            justify-self: flex-end;
            font-size: small;
            color: var(--color-text-subtle);

            @include breakpoint(small) {
                justify-self: normal;
            }
        }

        &--links {
            display: flex;
            flex-wrap: wrap;
            gap: var(--size-root-full);
            padding: var(--size-root-full) 0 0 0;
            justify-content: flex-start;
            width: 100%;
        }
    }

    &__art {
        position: sticky;
        top: var(--size-header);
        grid-area: art;
        display: flex;
        align-self: baseline;
        aspect-ratio: var(--ratio-square);
        width: var(--size-album-art);
        border-radius: var(--border-radius);
        box-shadow: var(--box-shadow-shallow);
        overflow: hidden;

        @include breakpoint(small) {
            position: static;
        }

        img {
            width: -webkit-fill-available;
            max-width: 100%;
            height: auto;
        }
    }

    &__tracks {
        grid-area: tracks;
    }

    &__track {
        display: flex;
        flex-wrap: wrap;
        gap: var(--size-root-half) var(--size-root-half);
        align-items: baseline;
        min-height: $size-touch;
        margin: var(--size-root-half) 0;
        padding: var(--size-root-quarter) var(--size-root-half);
        border-bottom: 1px solid var(--color-white-transparent);

        &:first-child {
            padding-top: calc(var(--size-root-half) + var(--size-root-quarter));
            border-top: 1px solid var(--color-white-transparent);
        }

        &--id {
            font-size: 80%;
        }

        &--title {
            font-size: 100%;
        }

        &--artist {
            font-size: 80%;
            margin: 0 0 0 auto;
            color: var(--color-text-subtle);

            @include breakpoint(small) {
                width: 100%;
                margin: 0;
            }
        }
    }

    &__lyrics {
        padding: var(--size-root-full);
        font-weight: 100;
        white-space: pre-line;
        line-height: calc(var(--line-height) * 1.5);
        background: var(--color-background-secondary);
    }

    details {
        width: 100%;
    }
}
