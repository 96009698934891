.trophos {
    --_color-range-01: rgb(30, 230, 20);
    --_color-range-02: rgb(30, 180, 0);
    --_color-range-03: rgb(30, 200, 20);

    position: relative;
    display: grid;
    gap: var(--size-root-full) var(--size-root-full);
    width: 100%;
    margin: auto;
    padding: var(--size-root-full) var(--size-root-full);
    max-width: var(--size-content);

    h1 {
        text-shadow: var(--text-shadow);
    }

    hr {
        width: 10vw;
        margin: var(--size-root-full) 60% var(--size-root-full) 0;
        border: none;
        border-bottom: var(--size-hr) solid var(--color-album-divider);
    }

    .numeration {
        display: block;
        margin: var(--size-root-full) 0 var(--size-root-2x) 0;
        padding: 0 var(--size-root-full);
        text-align: right;
    }

    pre {
        background: var(--color-background-secondary);
        border: 1px solid var(--color-border-all);
        padding: var(--size-root-full);
        border-radius: var(--border-radius);
        line-height: calc(var(--line-height) * 1.5);
    }

    code {
        white-space: pre-line;
    }

    .sequence > table {
        background-color: transparent;

        th {
            background-color: transparent;
        }

        tr:nth-child(1) {
            td:nth-child(1n) {
                color: var(--color-content);
                background: var(--color-table-row-alt);
            }

            td:nth-child(13n) {
                visibility: hidden;
                color: var(--color-background-primary);
                background: var(--color-background-primary);
            }        
        }
        
        tr:nth-child(2) {
            td:nth-child(1n) {
                color: var(--color-background-primary);
                background: var(--_color-range-01);
            }

            td:nth-child(26n) {
                color: var(--color-content);
                background: var(--color-background-primary);
            }        
        }
    }
    
    p:first-of-type > figure > img {
        aspect-ratio: 16/9;
    }
    
    &__image {
        position: relative;
        display: flex;
        width: 50%;
        justify-self: center;
        border-radius: var(--border-radius);
        box-shadow: var(--box-shadow-shallow);
        overflow: hidden;
        cursor: pointer;

        @include breakpoint(small) {
            width: 80%;
        }

        &:first-of-type {
            width: 100%;
            max-width: calc(100vw - var(--size-root-2x));
            margin-bottom: var(--size-root-full);
        }

        & img {
            width: -webkit-fill-available;
            width: 100%;
            height: auto;
        }

        figcaption {
            z-index: $depth-content;
            position: absolute;
            display: block;
            bottom: 0;
            right: 0;
            padding: var(--size-root-full);
            text-shadow: var(--text-shadow);
            pointer-events: none;
        }

        &--active {
            z-index: 100;
            position: fixed;
            display: grid;
            align-content: center;
            width: 100vw;
            height: 90vh;
            min-width: -webkit-fill-available;
            min-height: -webkit-fill-available;
            top: 0;
            left: 0;
            background: transparent;
            border: none;
            overflow: visible;
            animation: fadeIn var(--duration-quick);

            &:after {
                z-index: -1;
                position: fixed;
                content: "";
                display: block;
                width: 102vw;
                height: 102vh;
                top: 0;
                left: 0;
                background-color: var(--color-gallery-overlay);
                backdrop-filter: var(--backdrop-filter);
            }

            figcaption {
                position: relative;
                display: block;
                justify-self: center;
                padding: var(--size-root-full);
                color: var(--color-heading);
                animation: fadeUp var(--duration);
            }

            & > img {
                aspect-ratio: initial;
                width: auto;
                height: auto;
                max-width: 75vw;
                max-height: 75vh;
                margin: auto;
                // border-radius: var(--border-radius);
            }
        }
    }
}
