body {
  min-height: 100vh;
  min-height: -webkit-fill-available;
  background-color: transparent;
  
  &:before {
    z-index: $depth-negative;
    content: "";
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: linear-gradient(to bottom, var(--color-background-primary), var(--color-background-secondary));
    background-color: var(--color-background-primary);
  }

  // lock body when nav is open
  &.locked .main {
    filter: brightness(0.5);
    pointer-events: none;
  }

  &.modal {
    overflow: hidden;
  }
}

.header {
  z-index: $depth-header;
  position: sticky;
  top: 0;
  display: flex;
  justify-content: space-between;
  padding: 0 var(--size-root-half);
  background-color: var(--color-background-header);
  // backdrop-filter: var(--backdrop-filter-header);
}

.main {
  z-index: $depth-content;
  max-width: var(--width-max);
  min-height: calc(100vh - var(--size-header));
  margin: 0 auto;
  padding: 0;
  color: var(--color-text);
  font-size: var(--size-font);
}

.svg__brand {
  width: var(--size-brand);
}

.brand {
  justify-self: center;
  width: fit-content;
  margin: 0;
  transform: scale(1);
  cursor: pointer;

  @include breakpoint(small) {
    border: var(--focus-border-hidden);
  }

  &__svg {
    width: var(--size-brand);
    fill: var(--color-link);
  }
}
