.hero {
    font-family: sans-serif;
    scroll-snap-type: x mandatory;
    display: flex;
    width: 100%;
    max-width: var(--width-max);
    height: calc(100vh - var(--size-header));
    padding: 0;
    overflow-y: hidden;
    overflow-x: scroll;
    -webkit-overflow-scrolling: touch;

    @include breakpoint(small) {
        height: calc(100vh - var(--size-header));
    }

    &__nav {
        display: flex;
        position: absolute;
        width: 100%;
        top: 0;
        height: 100%;
        justify-content: space-between;
        align-items: center;
        pointer-events: none; // stop click interception
    }

    .slide {
        position: relative;
        flex: 1 0 100%;
        display: grid;
        grid-template: 1fr / auto minmax(auto, 1fr);
        justify-items: center;
        justify-content: center;
        align-items: center;
        height: 100%;
        scroll-snap-align: start;
        text-align: center;
        overflow: hidden;
        transition: all var(--duration);
        opacity: 0;

        @include breakpoint(small) {
            grid-template: auto auto / 1fr;
            gap: var(--size-root-full) 0;
            align-content: center;
            height: auto;
            min-height: 85vh;
        }

        &__details {
            display: flex;
            flex-wrap: wrap;
            gap: var(--size-root-full);
            padding: var(--size-root-2x) 2vw var(--size-root-2x) 2vw;
            text-align: left;
            opacity: 0;
            transition: all var(--duration);
            transform: translate3d(10vw, 0vh, 0);

            @include breakpoint(small) {
                order: +1;
                justify-content: center;
                padding: var(--size-root-full) 10vw var(--size-root-full) 10vw;
                text-align: center;
                transform: translate3d(0vw, -1vh, 0);
            }

            .title {
                width: 100%;
                font-size: xx-large;
                line-height: calc(var(--line-height) * 1.15);
                text-shadow: var(--text-shadow);

                @include breakpoint(small) {
                    display: none;
                }
            }

            .description {
                width: 100%;
                font-size: medium;
                line-height: calc(var(--line-height) * 1.5);
            }

            hr {
                width: 10vw;
                margin: var(--size-root-half) 60% var(--size-root-half) 0;
                border: none;
                border-bottom: var(--size-hr) solid var(--color-album-divider);

                @include breakpoint(small) {
                    display: none;
                    width: 20vw;
                    margin: var(--size-root-half) 0 var(--size-root-half) 0;
                }
            }
        }

        img {
            width: 45vw;
            max-width: 50vh;
            height: auto;
            margin: var(--size-root-2x) 0 var(--size-root-2x) 4vw; //removes unwanted clipping
            border-radius: var(--border-radius);
            box-shadow: var(--box-shadow-shallow);
            overflow: hidden;

            @include breakpoint(small) {
                width: 100%;
                max-width: 60vw;
                margin: 0 var(--size-root-2x);
            }
        }
    }

    .is-current {
        opacity: 1;

        .slide__details {
            transition-delay: var(--duration);
            opacity: 1;
            transform: translate3d(0vw, 0vh, 0);
        }
    }

    // hide scroll bar
    &::-webkit-scrollbar {
        width: 0px;
        background-color: transparent;
    }

    &::-webkit-scrollbar-track {
        background-color: transparent;
    }

    &::-webkit-scrollbar-thumb {
        background-color: transparent;
    }
}
