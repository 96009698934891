.contact {

    &-form {
        display: flex;
        flex-wrap: wrap;
        gap: var(--size-root-full);
        width: var(--size-contact-form);
        max-width: var(--size-contact-form-max);
        margin: auto;
        padding: var(--size-root-full);

        @include breakpoint(small) {
            width: 100vw;
        }

        &--title {
            font-size: xx-large;
            text-shadow: var(--text-shadow);
        }

        hr {
            width: 10vw;
            margin: var(--size-root-full) 60% var(--size-root-full) 0;
            border: none;
            border-bottom: var(--size-hr) solid var(--color-album-divider);
        }

        .element {
            display: grid;
            gap: var(--size-root-full);
            flex: 1 0 100%;

            label {
                color: var(--color-label);
            }

            input[type="text"],
            input[type="email"],
            input[type="tel"],
            textarea {
                max-width: 100%;
                min-height: $size-touch;
                padding: var(--size-root-half);
                color: var(--color-input-fg);
                background-color: var(--color-input-bg);
                border: 4px solid;
                border-color: var(--color-input-border);
                border-radius: var(--border-radius);
                font-family: var(--font-family-input);

                &:focus {
                    border-color: var(--color-link-h);

                    &::placeholder {
                        color: var(--color-placeholder-f);
                    }
                }
            }

            textarea {
                resize: vertical;
            }
        }
    }

    &-response {
        display: flex;
        flex-wrap: wrap;
        gap: var(--size-root-full);
        justify-content: center;
        width: var(--size-contact-form);
        max-width: var(--size-contact-form-max);
        margin: auto;
        padding: var(--size-root-full);
        text-align: center;

        @include breakpoint(small) {
            width: 100vw;
        }

        & p {
            flex: 1 0 100%;
            margin: 0 auto;
        }
    }
}