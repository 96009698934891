// #layout defaults

:root {
    // #custom content
    --size-brand: 60px;
    --size-header: 80px;
    --size-footer: 80px;
    --size-nav-width: 300px;
    
    --size-galery-media-width: 80vw;
    --size-galery-media-height: 80vh;
    
    // #icons
    --size-icon-2xlarge: 64px;
    --size-icon-xlarge: 32px;
    --size-icon-large: 24px;
    --size-icon-medium: 16px;
    --size-icon-small: 12px;

    // #spacing (padding/margin)
    --size-root-eigth: .125rem;
    --size-root-quarter: .25rem;
    --size-root-half: .5rem;
    --size-root-3quarter: .75rem;
    --size-root-full: 1rem;
    --size-root-2x: 2rem;

    // #aniimation
    --duration: 0.5s;
    --duration-quick: 0.25s;
    --duration-slow: 1.5s;

    // #typography
    --size-font: 1rem;
    --size-font-small: .8rem;
    --font-awesome: "Font Awesome 6 Free";
    --font-awesome-brands: "Font Awesome 6 Brands";
    --font-family: sans-serif;
    --font-family-heading: sans-serif;
    --font-weight: 400;
    --font-weight-light: 300;
    --font-weight-heavy: 700;
    --line-height: 1;

    // #scrollbars
    --size-width-track: var(--size-root-quarter);
    --size-width-scrollbar: var(--$size-root-quarter);

    // design elements
    --backdrop-filter: blur(5px);
    --border-radius: 0.5rem;
    --border-radius-full: 100%;
    // --slope: rotate(2deg);
    // --ratio-square: 1/1;

    // #tags
    --size-hr: 2px;

    // #forms
    --font-family-button: var(--font-family);
    --font-weight-button: var(--font-weight);

    --duration-button: 0.25s;
    --size-button-border: 1px;
    --border-radius-button: #{$size-border-radius};

    --size-button-border-h: 1px;
    --border-radius-button-h: #{$size-border-radius};

    --font-family-input: var(--font-family);
    --font-weight-input: var(--font-weight-light);
    --border-radius-input: #{$size-border-radius};

    --font-family-range: var(--font-family);
    --font-weight-range: var(--font-weight-light);
    --border-radius-range: #{$size-border-radius};

    --font-family-select: var(--font-family);
    --font-weight-select: var(--font-weight-light);
    --border-radius-select: #{$size-border-radius};

    --border-radius-checkbox: #{$size-border-radius};
    --font-family-checkbox: var(--font-family);
    --font-weight-checkbox: var(--font-weight-light);

    // #accessability
    --focus-border-hidden: 2px dashed transparent;
    --focus-border-active: 2px dashed;
}
