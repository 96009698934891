.modal {
    z-index: $depth-modal;
    position: fixed;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-content: center;
    width: 100vw;
    height: 100vh;
    padding: 10vh 10vw;
    top: 0;
    left: 0;
    background-color: var(--color-gallery-overlay);
    backdrop-filter: var(--backdrop-filter);
    animation: fadeIn var(--duration-quick);

    &__heading {
        display: flex;
        flex: 1 0 100%;
        justify-content: center;
        font-size: larger;
        padding: var(--size-root-full);
        animation: fadeDown var(--duration);
    }

    &__container {
        display: flex;
        flex-wrap: wrap;
        align-self: center;
        justify-self: center;
        width: auto;
        max-width: var(--size-galery-media-width);
        height: auto;
        max-height: var(--size-galery-media-height);
        top: auto;
        left: auto;
        background-color: var(--color-galery-background);
        border-radius: var(--border-radius);
        overflow: hidden;
        overflow-y: auto;
        overscroll-behavior: contain;
        -webkit-overflow-scrolling: touch;

        &:focus {
            border: 5px solid;
            border-color: var(--color-link-h);
        }

        img {
            width: auto;
            height: auto;
            max-height: 50vh;
            margin: 0 auto;

            @include breakpoint(small) {
                min-width: 100%;
            }
        }
    }

    .controls {
        display: contents; // remove bounding box to allow for "close anywhere" click
        position: relative;
        width: 100%;
        height: 100%;

        // can be used for all gallery buttons
        &--close {
            z-index: 1;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 0;
            cursor: pointer;
            color: var(--color-link);

            i {
                display: flex;
                align-items: center;
                justify-content: center;
                min-width: var(--size-icon-xlarge);
                width: var(--size-icon-xlarge);
                height: var(--size-icon-xlarge);
                font-family: var(--font-awesome);
                font-size: var(--size-root-2x);
            }

            &:hover,
            &:focus:not(:focus-visible) {
                color: var(--color-link-h);
            }

            &:focus-visible {
                color: var(--color-link-h);
                border: var(--focus-border-active);
            }
        }

        // dedicated close button positioning and style
        &--close {
            z-index: -1; //put behind images & content
            position: fixed;
            top: 0;
            left: 0;
            width: 100vw;
            height: 100vh;
            background: transparent;
            color: var(--color-link);
            border: var(--focus-border-hidden);
            cursor: pointer;

            &:focus-visible {
                color: var(--color-link-h);
                border: var(--focus-border-active);
            }

            &:hover,
            &:focus:not(:focus-visible) {
                color: var(--color-link-h);
            }

            i {
                position: absolute;
                top: 1.5rem;
                right: var(--size-root-full);
                width: var(--size-icon-xlarge);
                height: var(--size-icon-xlarge);

                &:before {
                    content: "\f00d";
                }
            }
        }
    }
}
